.events {
  padding: 1rem;

  &__title {
    margin-bottom: 1rem;
  }

  &__new-event-button {
    background-color: #4f3cc9;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.5rem;
    font-weight: 600;
    cursor: pointer;
    // // margin-right: 1rem; // Space between button and tabs
    // margin-left: 70%; // Push the button to the right
    float: right; // Make the button float to the right
    margin-right: 10px; // Add some margin to the right
  }

  &__controls {
    display: flex;
    align-items: center;
    overflow-x: auto;
    margin-bottom: 1rem;
    scrollbar-width: thin; // For supporting browsers
    scrollbar-color: #4f3cc9 #e0e0e0; // Scrollbar styling

    &::-webkit-scrollbar {
      height: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #e0e0e0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #4f3cc9;
      border-radius: 20px;
      border: 3px solid #e0e0e0;
    }
  }

  &__grid {
    // display: flex;
    // gap: 1rem;
    // overflow-x: auto; // Make the grid scrollable on mobile
    gap: 1rem;
    display: flex; // Use flexbox for horizontal layout
    overflow-x: auto; // Enable horizontal scrolling
    white-space: nowrap; // Keep all items in a single line
  }

  &__tabs {
    display: flex;
  }

  &__tab {
    flex: none; // Prevent tabs from growing with flexbox
    padding: 0.5rem 1rem;
    cursor: pointer;
    white-space: nowrap; // Keep tab names on a single line

    &--active {
      border-bottom: 3px solid #4f3cc9;
      width: 100%;
    }
  }

  &__events-container {
    display: flex;
    overflow-x: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      height: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #e0e0e0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #4f3cc9;
      border-radius: 20px;
      border: 3px solid #e0e0e0;
    }
  }


}
// .events {
  
//     &__grid {
//       display: grid;
//       grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
//       gap: 1rem;
//     }
//   }
  
  .event-card {
    background-color: $color-tertiary;
    border: 1px solid $color-secondary;
    // border-radius: $base-border-radius;
    box-shadow: $base-shadow;
    transition: transform 0.3s ease-in-out;
  
    &:hover {
      transform: translateY(-5px);
    }
  
    &__image {
        position: relative;
        padding-top: 100%;
        overflow: hidden;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        //   border-radius: $base-border-radius $base-border-radius 0 0;
        }
      }
    
      &__content {
        padding: 1rem;
        font-family: 'Nunito Sans', sans-serif;
        &__program-info,
        &__interests {
          margin: 10px 0;
        }
        &__visible {
            overflow: visible;
        }
      }
    
      &__name {
        font-weight: 600;
        margin-bottom: 0.5rem;
        font-size: 1.1rem; // Adjust font size as needed
      }
  }


  .event-detail {
    padding: 2rem 4rem;
    display: grid;
    grid-template-columns: 1fr 2fr; // Adjust proportions as needed
    gap: 1rem;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); //need to wrap things 
  
    &__image {
        width: 100%;
        height: auto;
        grid-row: 2; // Positioned on the second row
        // No need to specify grid-column as it's the first item in the grid
      }
    &__info {
        grid-column: 2 / 3; // Takes the second part of the grid
        grid-row: 2; // Positioned on the second row alongside the image
      }

      &__section, 
      &__tabs, 
      &__attendees {
        grid-column: 1 / -1; // Each section spans full width
        // Other styles...
      }
  
    // &__details {
    //   grid-column: 2 / 3; // Place details in the second column
    // }
    &__header {
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
    //   background-color: #f5f5f5;
        
      grid-column: 1 / -1; // Span the full width
      display: flex;
      align-items: center;
    }

    
    &__back-button {
        cursor: pointer;
        background-color: #4f3cc9; // A professional deep blue color
        color: white; // Contrasting text color for legibility
        border: none;
        padding: 0.75rem 1.5rem; // Sufficient padding for a button-like feel
        border-radius: 0.5rem; // Rounded corners for a modern look
        font-weight: 600; // Bold font weight
        font-size: 1rem;
        text-transform: uppercase; // Uppercase text for emphasis
        letter-spacing: 0.05rem; // Letter spacing for better readability
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2); // Subtle shadow for depth
        transition: background-color 0.3s ease; // Smooth transition for interactions
      
        &:hover {
          background-color: darken(#4f3cc9, 10%); // Slightly darker on hover for feedback
        }
      
        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba(79, 60, 201, 0.5); // Focus ring for accessibility
        }
      
        &:active {
          background-color: darken(#4f3cc9, 15%); // Even darker to simulate a pressed button
        }
      }

      &__link-button {
          cursor: pointer;
          background-color: $color-accent; // A professional deep blue color
          color: white; // Contrasting text color for legibility
          border: none;
          padding: 0.75rem 1.5rem; // Sufficient padding for a button-like feel
          border-radius: 0.5rem; // Rounded corners for a modern look
          font-weight: 600; // Bold font weight
          font-size: 1rem;
          text-transform: uppercase; // Uppercase text for emphasis
          letter-spacing: 0.05rem; // Letter spacing for better readability
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2); // Subtle shadow for depth
          transition: background-color 0.3s ease; // Smooth transition for interactions
      
          &:hover {
            background-color: darken(#4f3cc9, 10%); // Slightly darker on hover for feedback
          }
      
          &:focus {
            outline: none;
            box-shadow: 0 0 0 2px rgba(79, 60, 201, 0.5); // Focus ring for accessibility
          }
      
          &:active {
            background-color: darken(#4f3cc9, 15%); // Even darker to simulate a pressed button
          }
        }
    
    &__name {
      font-size: 2rem;
      font-weight: bold;
      margin: 0;
    }
    

    
    &__tabs {
      display: flex;
      border-bottom: 1px solid #ccc;
      margin-top: 1rem;
    }
    
    &__tab {
      padding: 0.5rem 1rem;
      cursor: pointer;
      &.active {
        border-bottom: 3px solid blue;
      }
    }
    
    &__attendees {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-top: 1rem;
      &.hidden {
        display: none;
      }
    }
    
    &__join-button {
      padding: 0.5rem 1rem;
      margin-top: 1rem;
      background-color: blue;
      color: white;
      border: none;
      cursor: pointer;
      &:hover {
        background-color: darken(blue, 10%);
      }
    }
    &__chips {
        // background-color: #eef2ff; // A light shade for highlighting, adjust color as needed
        // border-left: 4px solid #4f3cc9; // A solid color for the side border, adjust color as needed
        // padding-left: 0.5rem;
        margin-top: 1rem;
        display: flex; // Layout the chips in a row
        flex-wrap: wrap; // Allow wrapping to next line if needed
        gap: 0.5rem; // Spacing between chips
        h2 {
            display: block;
        }
    }

    &__organizer-chip {
        background-color: #4f3cc9; // Use a professional color
        color: white;
        padding: 0.5rem 1rem; // Padding inside chips
        border-radius: 1rem; // Rounded corners for chip shape
        font-size: 0.875rem; // Smaller font size for chips
        white-space: nowrap; // Prevents the text from wrapping inside the chip
        box-shadow: 0 2px 4px rgba(0,0,0,0.1); // Optional: subtle shadow for depth
        transition: background-color 0.2s; // Smooth transition for hover effect
        cursor: pointer;
        
        &:hover {
          background-color: darken(#4f3cc9, 10%); // Darken on hover
        }
      }
      &__attendee {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    
      &__attendee-image {
        width: 50px; // Adjust as needed
        height: 50px; // Adjust as needed
        border-radius: 50%; // Makes the image circular
        object-fit: cover; // Ensures the image covers the area without stretching
        margin-right: 1rem; // Space between the image and the name
      }
    
      &__attendee-name {
        font-size: 1rem; // Adjust as needed
        font-weight: 600; // Makes the name bold
      }
  }

  .events__grid-upcoming {
    display: grid;
    grid-template-columns: 1fr; // single column layout for small devices
    gap: 1rem; // add space between each card
    
  }
  @media (min-width: 769px) {
    .events__grid {
     

      &-upcoming {
        grid-template-columns: repeat(auto-fill, minmax(175px, 1fr)); // up to 5 columns for larger devices
      }
  
      .event-card {
        flex: 0 0 auto; // prevent cards from shrinking and ensure they maintain their set dimensions
        width: 200px; // set a fixed width
        min-height: 200px; // set a fixed height to maintain a square shape
        display: flex; // make the card a flex container
        flex-direction: column; // stack the image and content vertically
        
        &__image {
          position: relative;
          width: 100%; // make sure it fills the card width
          height: 60%; // allocate 60% of the card height for the image, adjust as needed
          
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover; // this ensures the image covers the area without stretching
          }
        }
  
        &__content {
          height: 40%; // allocate 40% of the card height for the content, adjust as needed
          overflow: auto; // add scroll if content is more
        }
      }
    }
    .event-detail {
      &__name {
        margin-top: -13px;
      }
    }
  }
@media (max-width: 768px) {
    .events {
      margin-bottom: 80px;
      padding: 0;
      &__grid {
        grid-template-columns: 1fr; // single column layout
        gap: 0.5rem; // reduced gap between cards
      }
      &__controls {
        margin-left: 15px;
      }
    }

    .events__grid {
        padding: 0 1rem;
    }
  
    .event-card {
        margin: .5rem 0;
        flex: 0 0 auto;
      &__image {
        padding-top: 60%; // reduced image area
      }
  
      &__content {
        padding: 0.5rem; // reduced padding
      }
  
      &__name {
        font-size: 0.9rem; // reduced font size for event names
      }
    }
    .event-detail {
        margin-bottom: 80px;
        grid-template-columns: 1fr; 
        padding: 0 1rem;
        padding-bottom: 100px;
        &__header {
            flex-direction: row; // Align items in a row
            justify-content: space-between; // Space out the back button and name
            align-items: center; // Center-align items vertically
            padding: 1rem 1rem 0 1rem;
        }

        // &__back-button {
        //     order: 1; // This will move the back button below the name despite being first in the HTML
        //   }
      
        //   &__name {
        //     order: 3; // This will ensure the name is on top of the back button
        //     text-align: center;
        //   }
        //   &__image {
        //     order: 2
        //   }
    
        &__name {
          font-size: 1.5rem;
          text-align: center;
          margin-bottom: 20px;
        }
    
        &__tabs {
          overflow-x: auto;
        }
        &__image,
        &__info,
        &__tabs, 
        &__attendees {
          grid-column: 1 / -1; // Takes full width
          grid-row: auto;
        }
      }
  }