// COLORS

// Color: Tea Green - Uses: Background color, light-themed sections
$color-primary: #D0F0C0;  

// Color: Oolong Brown - Uses: Text, headers, and secondary buttons or elements
$color-secondary: #905e26; 

// Color: Rice Paper White - Uses: Backgrounds for modals, cards, 
// and other UI elements that need to stand out from the primary background
$color-tertiary: #FAFAD2;

// Color: Bamboo Green - Uses: Accent elements like buttons, icons, or highlights
$color-accent: #6B8E23; 

// Color: Ink Black - Uses: Primary text color
$color-text: #1C1C1C;

// $color-logo: #87CEEB;


// Fonts
$base-font-family: 'EB Garamond', 'Nunito Sans', serif;


// Borders
$base-border-radius: 8px;

// Shadows
$base-shadow: 0 2px 10px rgba(0,0,0,0.1);
