.profile-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 600px;
    margin: 1.5rem auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  
    &__section {
      display: flex;
      flex-direction: column;
      gap: 10px;
  
      h2 {
        font-size: 1.2em;
        color: #333;
        
      }
    }

    



    &__checkbox-list, &__select-list {
        background-color: $color-tertiary;
        // border: 1px solid $color-secondary;
        // border-radius: $base-border-radius;
        box-shadow: $base-shadow;
        // position: fixed;
        border-top: 1px solid #ccc;
        margin-bottom: 10px;
        list-style-type: none;
        // padding: 10px;
        z-index: 10;
        max-height: 200px;
        overflow-y: auto;
        min-width: 250px;
        transition: transform 0.3s ease-in-out;

        &--search-filter {
          min-height: 0;
          input {
            min-width: 100%;
          }
        }
    }

    &__checkbox-list-item {
        padding: 10px;
        margin: 5px;
      }
    
      &__checkbox-list-item:hover,
      &__checkbox-list-item.selected {
        background-color: $color-secondary;
      }

      &__checkbox-list-item, &__select-list-item {
        // background-color: $color-primary;
        color: $color-text;
        border-radius: $base-border-radius;
        padding: 0.5rem 1rem;
        margin: 0.5rem 0;
        font-weight: 600;
        display: flex;
        align-items: center;
      }

      &__checkbox-search { 
        border: 1px solid $color-secondary;
        border-radius: $base-border-radius;
        width: 100%; // to ensure it takes the full width of the container
        padding: 10px; // add some padding for better visuals
        margin-bottom: 15px; // add some margin between the search and the list
        background-color: lighten($color-tertiary, 10%);
        transition: background-color 0.3s ease-in-out;
        &:focus {
          background-color: lighten($color-tertiary, 20%);
        }
    }

    &__select-search { 
        border: 1px solid $color-secondary;
        border-radius: $base-border-radius;
        width: 100%; // to ensure it takes the full width of the container
        padding: 10px; // add some padding for better visuals
        margin-bottom: 15px; // add some margin between the search and the list
        background-color: lighten($color-tertiary, 10%);
        transition: background-color 0.3s ease-in-out;
        &:focus {
          background-color: lighten($color-tertiary, 20%);
        }
    }

    &__label {
      display: flex;
      flex-direction: column;
      font-size: 1em;
    // Change the color of the label
        font-weight: bold; // Make the label bold

        p {
          color: #007BFF; // Change the color of the paragraph under the label
          font-size: 0.9em; // Make the font size smaller
          margin-top: 5px; // Add some space above the paragraph
        }
  
     
    }
    &__input {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 1em;
        
         &--text-area {
            resize: vertical; // allows the user to resize the textarea vertically
            min-height: 100px; // sets a minimum height for the textarea
            background-color: lighten($color-tertiary, 10%); // sets the background color
            transition: background-color 0.3s ease-in-out; // adds a transition effect for the background color
            border: 1px solid $color-secondary; // adds a border to the textarea
            border-radius: $base-border-radius; // adds a border radius to the textarea
            padding: 10px; // adds some padding for better visuals
            font-size: 1em; // sets the font size of the textarea
            &:focus {
              background-color: lighten($color-tertiary, 20%); // changes the background color when the textarea is focused
              border-color: darken($color-secondary, 10%); // darkens the border color when the textarea is focused
            }
        }
        &--date-picker {
            appearance: none;
            background-color: lighten($color-tertiary, 10%);
            border: 1px solid $color-secondary;
            border-radius: $base-border-radius;
            padding: 10px;
            font-size: 1em;
            transition: background-color 0.3s ease-in-out;
            &:focus {
              background-color: lighten($color-tertiary, 20%);
              border-color: darken($color-secondary, 10%);
            }
        }
      }

    &__photo {
        width:150px; // Set a fixed width
        height: 150px; // Set a fixed height
        object-fit: cover; // Ensure the aspect ratio of the image is maintained
        border-radius: 50%; // Make the image circular
        margin-bottom: 20px;
    }
  
    &__submit-button {
      padding: 10px;
      background-color: #007BFF;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }

  @media (max-width: 768px) {
    .profile-form {
      margin-top: 4em;
    }
  }

  .arrow {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 10px;
    border-right: 2px solid #333;
    border-bottom: 2px solid #333;
    transform: rotate(-45deg);
    transition: transform 0.3s ease;

    &.right {
      transform: rotate(-45deg);
    }

    &.down {
      transform: rotate(45deg);
    }
  }
