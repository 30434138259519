.app {
    text-align: center;
  }
  
  .app__logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .app__logo {
      animation: app-logo-spin infinite 20s linear;
    }
  }
  
  @keyframes app-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: rgba(255,255,255,0.9); // Light overlay background
    position: fixed; // Overlay over the entire screen
    top: 0;
    left: 0;
    z-index: 9999; // Ensure it's above other content
  
    .loading-text {
      font-size: 1.5rem;
      font-weight: bold;
      color: #4f3cc9; // Use your app's primary color
      text-transform: uppercase;
  
      &::after {
        content: ' .';
        animation: dots 1s steps(5, end) infinite;
      }
    }
  
    @keyframes dots {
      0%, 20% {
        color: rgba(0,0,0,0);
        text-shadow:
          .5em 0 0 rgba(0,0,0,0),
          1em 0 0 rgba(0,0,0,0);
      }
      40% {
        color: #4f3cc9;
        text-shadow:
          .5em 0 0 rgba(0,0,0,0),
          1em 0 0 rgba(0,0,0,0);
      }
      60% {
        text-shadow:
          .5em 0 0 #4f3cc9,
          1em 0 0 rgba(0,0,0,0);
      }
      80%, 100% {
        text-shadow:
          .5em 0 0 #4f3cc9,
          1em 0 0 #4f3cc9;
      }
    }
  }
  
  