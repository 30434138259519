// padding: 6rem;
// $padding-values: (1, 2, 3, 4, 5, 6);

// @each $value in $padding-values {
//   &__padding#{$value} {
//     padding: #{$value}rem;
//   }
// }
.login-form {
  a {
    // color: $color-text;
    cursor: pointer;
    &:hover {
        color: $color-secondary
      }
  }
    &__container-center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
      }

    &__container {
      position: relative;
      max-width: 400px;
      min-width: 340px;
      min-height: 290px;
      margin: 0 auto;
      padding: 20px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0,0,0,0.1);
      display: flex;
      flex-direction: column;
      gap: 20px;
      a {
        color: $color-text;
        cursor: pointer;
        &:hover {
            color: $color-secondary
          }
        }
    }
  
    &__input {
      width: 100%;
      border: 1px solid #ddd;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 10px;
      font-size: 14px;
      box-sizing: border-box;
    }
  
    &__button {
      width: 100%;
      background-color: #4f3cc9; // Adjusted to match your app's color scheme
      color: white;
      border: none;
      padding: 10px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: darken(#4f3cc9, 10%); // Darken for hover state
      }
  
      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(79, 60, 201, 0.5); // Focus state for accessibility
      }
    }
    &__error {
        min-height: 20px; // Reserve enough space for one line of text
        color: red;
        transition: min-height 0.3s ease; // Optional: smooth transition if you want
    }
    @media (min-width: 1000px) {
      &__form-padding {
        padding: 8rem 30rem;

      }
    }
    @media (max-width: 999px) {
      &__form-padding {
        padding-top: 5rem;
      }
    }
  }


 