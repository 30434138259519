.user-detail {
    // Increased side padding
    // background-color: $color-tertiary;

    a {
        color: $color-accent;
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto; // Adjusted here
        gap: 1rem;
    }

    &__header {
        grid-column: 1 / -1; // Span the full width
        display: flex;
        justify-content: space-between; // Adjusted here
        align-items: center;
        padding: 1rem;
        // background-color: $color-primary;
        // border: 1px solid $color-text;
        // border-radius: $base-border-radius;
        // box-shadow: $base-shadow;
    }

    &__back-button {
        padding: 0.5rem 1rem;
        border: 1px solid $color-secondary;
        background-color: $color-primary;
        color: $color-text;
        border-radius: $base-border-radius;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: darken($color-primary, 10%);
        }
    }
    &__grid-photo {
        grid-column: 1 / 2;
        max-width: 300px;  // Limit the width to ensure it doesn't exceed the length of the personal info
        img {
            width: 100%;
            height: auto;  // Maintain the aspect ratio
            object-fit: cover;
            border-radius: $base-border-radius;  // Optional: to round the corners
        }
    }

    &__grid-info {
        padding: 1rem;
        border-radius: $base-border-radius;
        div {
            margin: 1rem 0;
        }
        
    }

    &__image {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        margin-right: 1rem;
    }

    &__name {
        font-size: 2rem;
        color: $color-secondary;
        margin: 0;
    }

    &__info {
        padding: 2rem;
        border-radius: $base-border-radius;
        box-shadow: $base-shadow;
    }

    &__section {
        &-title {
            font-size: 1.5rem;
            color: $color-secondary;
        }
    }
    .gi-personal {
        padding-top: 0;
    }
}

@media (min-width: 768px) {
    .user-detail {
    flex-direction: row;
    justify-content: space-between;
    &__section {
        margin-bottom: 2rem;
    }
    &__section-title {
        margin-top: -10px;
        margin-bottom: 1rem;
    }
    }
  }

@media (max-width: 768px) {
    .user-detail {
        padding: 1rem;

        &__grid {
            grid-template-columns: 1fr;
        }

        &__grid-photo {
            grid-row: 1;
            grid-column: 1;
            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }

        &__grid-info {
            // grid-row: 2 / 3;
            grid-column: 1;
            padding: 0 0.5rem;
            // padding-top: 0;
        }

        &__header {
            flex-direction: column;
            align-items: start;
            &__back-button {
                align-self: stretch;
                margin-bottom: 1rem;
            }
        }
        &__section-title {
            margin-bottom: .5rem;
        }
    }
}
