// base/_base.scss

body {
    font-family: $base-font-family;
    color: $color-text;
    // background-color: $color-tertiary;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input, textarea {
    font-family: $base-font-family;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

a {
    color: #6B8E23;
    text-decoration: none;
    transition: color 0.3s ease-in-out;

    &:hover {
        color: darken(#6B8E23, 15%);
    }   
}

.pointer {
    cursor: pointer;
}

.pre-wrap {
    white-space: pre-wrap;
}


button {
    font-family: $base-font-family;
    &:disabled {
        background-color: grey;
    }
}

small {
    margin: .5em 0;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}
