.app__header {
    background-color: $color-secondary;
    padding: .25rem;
    display: flex;
    justify-content: space-between;  // Adjusts space distribution
    align-items: stretch;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 10;
}

.dropdown {
    position: relative;
    display: flex;
    align-items: center; // Align items vertically
  
    &__button {
      background: none; // Remove button background
      border: none; // Remove button border
      cursor: pointer; // Add hand cursor on hover
      font-size: 1.5rem; // Match the font size of the links
      color: $color-tertiary; // Match the color of the links
      padding-right: 20px;
      i {
        &:hover {
            color: darken($color-accent, 20%);
        }
      }
    }
  
    &__content {
      position: absolute;
      min-width: 160px;
      top: 60px; // Positions the dropdown right below the button
      right: 0;
      z-index: 1;
      background-color: $color-secondary; // Or any color you prefer
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); // Adds a little shadow for "lifting" effect
      border-radius: 5px; 
  
      a {
        color: black;
        padding: 10px 20px; 
        text-decoration: none;
        display: block;
      }
    }
  
    &:hover {
      &__content {
        display: block;
      }
    }
  }

  .nav__links .header-logo, .top__header-bar .header-logo {
    font-size: 2rem; /* Adjust as needed */
    font-weight: bold; /* Makes the text thicker */
    color: gold; /* Use your primary color */
    // text-align: center; /* Centers the text */
    flex-grow: 1; /* Allows the logo to take up remaining space */
    margin: auto;
    &:hover {
        color: gold;
    }
  }

.nav__links, .bottom__tab-bar, .top__header-bar {
    display: none;  // Hide both by default
    gap: 1rem;
    a {
        color: $color-tertiary;
        text-decoration: none;
        padding: 0.5rem 1rem;
        font-size: 1.5rem;
        border-radius: $base-border-radius;
        transition: background-color 0.3s ease-in-out;

        &:hover {
            color: darken($color-accent, 20%);
        }
    }
}


.logout {
    margin-left: auto;
}

// Desktop styles
@media (min-width: 769px) {
    .nav__links.desktop {
        display: flex;  // Show desktop nav
        align-items: center;
        justify-content: flex-start;  // Align links to the left
        gap: 1rem;
        width: 100%;
        .logout {
            margin-left: auto;  // Push logout to the far right
        }
    }
    .top__header-bar.mobile {
        display: none;
    }
    .header-logo {
        padding-left: .5em;
    }
}

// Mobile styles
@media (max-width: 768px) {
    .app__header {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 101;
        padding: 0;
    }
    .top__header-bar.mobile {
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        justify-content: space-between;
        align-items: center;
        gap: .4rem;
      
      }
      .menu-icon, .chat-icon {
        font-size: 1.5rem;
        color: $color-tertiary;
        padding: 1rem;
      }
    .bottom__tab-bar.mobile {
        display: flex;  // Show mobile bottom tab bar
        justify-content: space-around;
        background-color: $color-secondary;
        position: fixed;
        bottom: 0;
        width: calc(100vw);
        bottom: env(safe-area-inset-bottom);
        padding: 0.5rem 0;
        z-index: 100;
    }
    .dropdown {
        &__content {
            top: 100%;
            min-width: 100px;
            border-radius: 0;
            a {
                font-size: 1rem;
                padding: .5rem;
            }
        }
    }
}
