/* Directory */
.directory {
  
    &__filter-container {
      display: flex;
      position: sticky;
      top: 60px;
      // flex-wrap: wrap;
      gap: 10px;
      z-index: 5;
      margin-bottom: 20px;
      background-color: $color-tertiary;
      padding: 1rem;
      border-radius: $base-border-radius;
      box-shadow: $base-shadow;
      overflow-x: auto;  // For horizontal scrolling on mobile
      scrollbar-width: none;  // Hide scrollbar for Firefox
      &::-webkit-scrollbar {
        display: none;  // Hide scrollbar for Chrome, Safari and Opera
      }
    }
  
    &__filter-item,
    &__filter-button {
      background-color: $color-primary;
      color: $color-text;
      border-radius: $base-border-radius;
      padding: 0.5rem 1rem;
      margin: 0.5rem 0;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
    
    &__filter-search { 
        border: none;
        width: 100%; // to ensure it takes the full width of the container
        padding: 5px; // add some padding for better visuals
        margin-bottom: 10px; // add some margin between the search and the list
        font-size: 16px;
        position: sticky;
    }
    &__filter-list {
        background-color: $color-tertiary;
        // border: 1px solid $color-secondary;
        // border-radius: $base-border-radius;
        box-shadow: $base-shadow;
        position: fixed;
        border-top: 1px solid #ccc;
        margin-bottom: 10px;
        list-style-type: none;
        padding: 10px;
        z-index: 100;
        min-height: 200px;
        max-height: 200px;
        overflow-y: auto;
        min-width: 250px;
        transition: transform 0.3s ease-in-out;
        &.visible {
          display: block;
        }
        &.hidden {
          display: none;
        }
        &--search-filter {
          min-height: 0;
          input {
            min-width: 100%;
          }
        }
        &--top {
          z-index: 100;
        }
        &--inner {
          box-shadow: none;
        }
    }
      
  
    &__filter-list-item {
      padding: 10px;
      margin: 5px;
    }

    &__filter-slider {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
      // background-color: $color-tertiary;
      border-radius: $base-border-radius;
      box-shadow: $base-shadow;
      .slider-values {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }
    .slider {
        position: relative;
    }
  }

  &__filter-slider__input {
      width: 100%;
      height: 15px;
      border-radius: 5px;
      background: #d3d3d3;
      outline: none;
      opacity: 0.7;
      transition: opacity .2s;
      position: absolute;
      pointer-events: none; 

      &::-webkit-slider-thumb {
          appearance: none;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: $color-primary;
          cursor: pointer;
      }

      &::-moz-range-thumb {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: $color-primary;
          cursor: pointer;
      }

      &:hover {
          opacity: 1;
      }
  }
  &__filter-slider__input:first-of-type {
    z-index: 1; // put the first slider above the second one
    pointer-events: auto; // enable pointer events for the first slider
}
  
    &__filter-list-item:hover,
    &__filter-list-item.selected {
      background-color: $color-secondary;
    }
  
    &__card-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 1rem;
      padding: 1rem;
      transition: grid-template-columns 0.3s ease-in-out;
    }
  
    &__card {
      background-color: $color-tertiary;
      border: 1px solid $color-secondary;
      border-radius: $base-border-radius;
      box-shadow: $base-shadow;
      transition: transform 0.3s ease-in-out;
  
      &:hover {
        transform: translateY(-5px);  // subtle lift on hover
      }
    }
  
    &__card__image {
        position: relative;
        padding-top: 100%;  // This enforces a 1:1 aspect ratio
        overflow: hidden;  // This will hide any part of the image that doesn't fit
        
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;  // This will ensure the image covers the area without stretching
            border-bottom: 1px solid $color-secondary;  // separation line
            border-radius: $base-border-radius $base-border-radius 0 0;  // top rounded corners
          }
    }
  
    &__card-content {
      font-family: 'Nunito Sans', sans-serif;
      padding: 1rem;
  
      &__program-info,
      &__interests {
        margin: 10px 0;
      }
    }
  }
  
  @media (min-width: 769px) {
    .directory {
      // h2 {
      //   font-size: 1.3em;
      // }
      // p {
      //   font-size: .9em;
      // }

    //   &__card__image {
    //     overflow: hidden;  // This will hide any part of the image that doesn't fit
        
    //     img {
    //         width: 50%;  // reduce image width by half
    //         height: 50%;  // reduce image height by half
    //         object-fit: cover; 
    //       }
    // }
  
    &__card-content {
      padding: 0.5rem;  // reduce padding by half
  
      &__program-info,
      &__interests {
        margin: 5px 0;  // reduce margin by half
      }
    }
    }
  }

  // Mobile styles
  @media (max-width: 768px) {
    .directory {
      &__card-grid {
        grid-template-columns: repeat(2, 1fr);  // two column layout
      }
  
      &__filter-container {
        overflow-x: scroll;  // horizontal scrolling for filters
        white-space: nowrap;  // keep filters in one line
        position: fixed;
        top: 60px;  // Assume the header is 60px high
        width: 100%;
        z-index: 10; 
      }
      
      &__card__image {
        padding-top: 60%;  // This reduces the size of the image to half
      }
      &__card {
        padding: 0.5rem;  // reduce padding
        &__image {
          padding-top: 60%;  // reduce image size
        }
      }
      &__card-content {
        padding: 0.5rem;  // reduce padding
        font-size: 0.8rem;  // reduce font size
  
        &__program-info,
        &__interests {
          margin: 5px 0;  // reduce margin
        }
      }
      
      &__filter-item,
      &__filter-button {
        padding: 0.25rem 0.5rem;  // reduce padding
        margin: 0.25rem 0;  // reduce margin
        font-size: 0.8rem;  // reduce font size
      }
      &__filter-list-item {
        padding: 5px;
        margin: 2px;
      }

      &__filter-list {
        max-width: calc(100% - 20px);  // Ensures the list won't overflow the screen, 20px for some padding
        margin-right: 10px;  // Ensures a 10px padding from the right edge of the viewport
        border-top: none;
      }
  
    }
  }
  