.teahouse {
    &__welcome {
        font-size: 2.3em;
        color: $color-text;
        margin-bottom: 1em;
        text-align: center;
    }
    &__container {
        display: flex;
        flex-wrap: wrap; // Allow items to wrap in smaller screens
        // justify-content: center; // Center items
        gap: 1em; // Add gap between items
    }

    &__person {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0.5em;
        border: 1px solid $color-text;
        padding: 1em;
        border-radius: $base-border-radius;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // subtle shadow for depth
        transition: all 0.3s ease; // smooth transition for interactions

        &:hover {
            transform: translateY(-5px); // subtle hover effect
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
            cursor: pointer;
        }
    
        &-photo {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover; // Ensure images fit well
            border: 2px solid $color-secondary;
        }
    
        &-name {
            font-size: 1em;
            color: $color-text;
            margin-top: 0.5em;
        }
    }
  
    &__section {
        // background-color: $color-tertiary;
        padding: 1em;
        // border-radius: $base-border-radius;
        margin-bottom: 2em;
        // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

        &-title {
            font-size: 1.5em;
            color: $color-secondary;
            margin-bottom: 1em;
        }
    }
}

@media (max-width: 768px) {
    .teahouse {
        margin-top: 4em;

        &__welcome {
            font-size: 1.5em; // Slightly smaller font for mobile
        }

        &__container {
            flex-direction: column; // Stack items vertically on smaller screens
        }

        &__person {
            width: 80%; // Full width for mobile view
        }
    }
}
