.contact-detail {

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;

        &-info {
            display: flex;
            align-items: center;
        }
    }

    &__back-button {
        padding: 0.5rem 1rem;
        border: 1px solid #ccc; // Replace with desired color
        background-color: #fff; // Replace with desired color
        color: #333; // Replace with desired color
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background-color: darken(#fff, 5%);
        }
    }

    &__image {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        margin-right: 1rem;
        object-fit: cover;
    }

    &__name {
        font-size: 1.5rem;
        color: #333; // Replace with desired color
    }

    &__info {
        &__section {
            background-color: #fff; // Replace with desired color
            padding: 1rem;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            margin-bottom: 1rem; // Spacing between sections

            h2 {
                color: #333; // Replace with desired color
                margin-bottom: 1rem;
            }

            ul {
                list-style: none;
                padding: 0;
    
                li {
                    margin-bottom: 0.5rem;
                }
            }

            p {
                margin-bottom: 0.5rem;
                strong {
                    color: #333; // Replace with desired color
                }
                a {
                    color: #0066cc; // Replace with desired color
                }
            }
            .editable-text {
                border: 1px solid #ccc;
                border-radius: 4px;
                padding: 0.5rem;
                width: 100%;
                min-height: 100px; // Adjust as needed
            }
    
            // Style for interactive buttons
            .btn {
                padding: 0.5rem 1rem;
                margin-right: 0.5rem;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                background-color: #007bff; // Primary button color
                color: white;
                &:hover {
                    background-color: darken(#007bff, 10%);
                }
            }
    
            // Style for add interaction/task/tag input
            .add-input {
                border: 1px solid #ccc;
                border-radius: 4px;
                padding: 0.5rem;
                margin-bottom: 0.5rem;
            }
        }
    }
    &__tags {
        ul {
            list-style: none;
            display: flex;;
            padding: 0;
        }
        li {
            margin: 0 .5em;
            background-color: aquamarine;
            border-radius: 1em;
        }
    }
    &__tag {
        display: inline-flex;
        align-items: center;
        margin: 0.5em;
        background-color: aquamarine;
        border-radius: 1em;
        padding: 0.5em 1em;
        &__remove-icon {
            margin-left: 0.5em;
            cursor: pointer;
            color: #333; // or any color that suits your design
    
            &:hover {
                color: #ff0000; // color change on hover (optional)
            }
        }
    }
    &__info-grid {
        display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
    }

    &__history-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &-interact {
            display: grid;
            grid-template-rows: repeat(2, auto);
            gap: 20px;
        }
    }

}


@media (max-width: 768px) {
    .contact-detail {
        &__header {
            flex-direction: row;
            justify-content: space-between;

            &-info {
                flex-direction: row;
                justify-content: space-between;
            }
        }

        &__image {
            margin-right: 1rem;
        }
        &__info-grid {
            grid-template-columns: repeat(1, 1fr);
            gap: 0;
        }
        &__notes {
            margin-top: 10px;
        }
        &__history-grid {
            grid-template-columns: repeat(1, 1fr);
            margin-top: 20px;
            gap: 20px;
        }
    }
}
