// Shared Class
.rounded {
    border-radius: $base-border-radius;
}
  
.shadow {
    box-shadow: $base-shadow;
}

.reverse {
    flex-direction: row-reverse;
}

.required-star {
    color: red;
}

.error-text {
    color: red;
    font-size: 0.9rem; /* Adjust font size as needed */
    margin-top: 5px; /* Space above the error message */
}

// .smooth-trans {
//     /* Applies a smooth transition for transform and opacity properties */
//     transition: transform 1s ease-in-out, opacity 1s ease-in-out;
//     &.visible {
//         opacity: 1;
//         transform: translateY(0);
//       }
//       &.hidden {
//         opacity: 0;
//         transform: translateY(-20px);
//       }
// }

.item-enter {
    opacity: 0;
  }
  
  .item-enter-active {
    opacity: 1;
    transition: opacity 400ms;
  }
  
  .item-exit {
    opacity: 1;
  }
  
  .item-exit-active {
    opacity: 0;
    transition: opacity 400ms;
  }


// .no-focus {
//     input:focus {
//         outline:none;
//     }
// }



@media (max-width: 768px) { 
    .app-root {
        // max-width: calc(100vw - 1rem); // subtract 1rem from 100% of the viewport width
        max-height: calc(100vh - 60px); // subtract 60px from 100% of the viewport height
    }
}
